@import "@/styles/_variables.scss";























 .uk-switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 20px;
}
.uk-switch input {
  display: none;
}
.uk-switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 4px;
  bottom: 0;
  transition: 0.3s;
  border-radius: 30px;
  background-color: var(--muted-text-color);

}
.uk-switch-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  top: 2px;
  background-color: var(--white-color);
  transition: 0.3s;
  border-radius: 50%;
}
input:checked + .uk-switch-slider {
    background: var(--app-start-color);
}
input:checked + .uk-switch-slider:before {
  transform: translateX(21px);
}
.disabled:before {
  background-color: var(--muted-text-color);
}

.uk-switch-slider.disabled {
  background-color: var(--app-secondary-color);
  border: 1px solid var(--muted-text-color);
  left: -2px;
  top: -2px;
  cursor: not-allowed;
}
